import { ExclamationMarkCircle } from '@sqs/rosetta-icons';
import { Button, Flex, Text } from '@sqs/rosetta-primitives';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { cancelUserInvite, removeUserFromOrg } from '../../apis/sqspUsers';
import { FEATURE_FLAGS } from '../../const/feature-flags';
import { NAV_USERS, NAV_USERS_INVITED } from '../../const/navigation';
import { usePlatformBreakpoint } from '../../hooks/usePlatformBreakpoint';
import { selectEnterprise } from '../../stores/currentUser';
import { fetchInvitedOrgUsers } from '../../stores/invitedOrgUsers';
import { sendErrorMessage, sendSuccessMessage } from '../../stores/messages';
import { RootState } from '../../stores/rootReducer';
import { useAppDispatch, useAppSelector } from '../../stores/store';
import { t, T } from '../../i18n';
import { GenericModal } from '../common/GenericModal';
import { OrgUser } from '../../models/SqspUser';
import { fetchOrgUsers } from '../../stores/orgUsers';
import { ModalTitle } from '../common/ModalTitle';
import { FeatureFlagContext } from '../FeatureFlagProvider';

interface UserChangeRoleModalProps {
  readonly orgUser: OrgUser;
  readonly closeModal: () => void;
}

export const UserRemoveUserFromOrgModal = ({ orgUser, closeModal }: UserChangeRoleModalProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isMobile } = usePlatformBreakpoint();
  const { enterpriseId } = useAppSelector((state: RootState) => ({
    enterpriseId: selectEnterprise(state.currentUser)?.id
  }));
  const { flagEnabled } = useContext(FeatureFlagContext);

  const onClick = () => {
    if (enterpriseId && orgUser) {
      if (orgUser.status === 'ACCEPTED') {
        removeUserFromOrg(enterpriseId, orgUser.id).
        then(() => {
          dispatch(
            sendSuccessMessage(t("User was successfully removed from the organization",
            {}, { project: 'enterprise-dashboard' })
            )
          );
          closeModal();
          dispatch(fetchOrgUsers(enterpriseId));
        }).
        catch(() => {
          dispatch(
            sendErrorMessage(t("There was an error removing this user from the organization. Please try again",


            {}, {
              project: 'enterprise-dashboard' })

            )
          );
        }).
        finally(() => {
          navigate(NAV_USERS);
        });
      } else {
        cancelUserInvite(enterpriseId, orgUser.inviteCode).
        then(() => {
          dispatch(
            sendSuccessMessage(t("User Invitation was successfully canceled",
            {}, { project: 'enterprise-dashboard' })
            )
          );
          closeModal();
          dispatch(fetchInvitedOrgUsers(enterpriseId));
        }).
        catch(() => {
          dispatch(
            sendErrorMessage(t("There was an error canceling this user invitation. Please try again",


            {}, {
              project: 'enterprise-dashboard' })

            )
          );
        }).
        finally(() => {
          navigate(NAV_USERS_INVITED);
        });
      }
    }
  };

  const triggerCloseModal = () => {
    closeModal();
  };

  return (
    <GenericModal
      closeModal={triggerCloseModal}
      modalActions={
      <Flex justifyContent="space-between" mt={isMobile ? 0 : 6}>
          <Button.Tertiary type="button" onClick={triggerCloseModal}>
            <T project="enterprise-dashboard">{"Cancel"}</T>
          </Button.Tertiary>
          <Button.Tertiary type="button" onClick={onClick} color="red.400">
            {orgUser.status === 'ACCEPTED' ? <T
            project="enterprise-dashboard">{"Remove user"}</T> : <T

            project="enterprise-dashboard">{"Confirm"}</T>
          }
          </Button.Tertiary>
        </Flex>
      }>

      <ModalTitle>
        {orgUser.status === 'ACCEPTED' ?
        <>
            <ExclamationMarkCircle color="red.400" sx={{ marginBottom: '-4px' }} />
            &nbsp;<T project="enterprise-dashboard">{"Remove user from org?"}</T>
          </> :

        <>
            <ExclamationMarkCircle color="red.400" sx={{ marginBottom: '-4px' }} />
            &nbsp;<T project="enterprise-dashboard">{"Cancel invitation?"}</T>
          </>
        }
      </ModalTitle>
      {orgUser.status === 'ACCEPTED' ?
      <Text.Body>This user will be removed from the org and will lose access to all Scheduling Instances.</Text.Body> :

      <Text.Body>This user will no longer be invited to the organization.</Text.Body>
      }
    </GenericModal>);

};