import { ActionList } from '@sqs/rosetta-compositions';
import { Divider, Stack, TextLink } from '@sqs/rosetta-elements';
import { ColumnDef, Table } from '@sqs/rosetta-experimental';
import { Box, Flex, Text } from '@sqs/rosetta-primitives';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FEATURE_FLAGS } from '../../const/feature-flags';
import { NAV_USER_VIEW } from '../../const/navigation';
import { PAGINATION_LIMIT_MIN } from '../../const/pagination';
import { ACTIONS_IDENTIFIER } from '../../const/table';
import { usePlatformBreakpoint } from '../../hooks/usePlatformBreakpoint';
import { t, T } from '../../i18n';
import { ORG_MEMBER, OrgUser, SqspUser, sqspUserRoleMap } from '../../models/SqspUser';
import { selectEnterprise } from '../../stores/currentUser';
import { fetchInvitedOrgUsers, selectAll as selectAllInvitedOrgUsers } from '../../stores/invitedOrgUsers';
import { fetchOrgUsers, selectAll as selectAllOrgUsers } from '../../stores/orgUsers';
import { RootState } from '../../stores/rootReducer';
import { useAppDispatch, useAppSelector } from '../../stores/store';
import { enterpriseHasSsoProvider } from '../../utils/enterpriseUtils';
import { isPendingInvitationStatus } from '../../utils/sqspUserUtils';
import { tsToDateString } from '../../utils/time';
import { AcuityLoader } from '../common/AcuityLoader';
import { TableHeader } from '../common/Table/TableHeader';
import { TablePagination } from '../common/Table/TablePagination';
import { UserStatusChip } from '../common/UserStatusChip';
import { FeatureFlagContext } from '../FeatureFlagProvider';
import { UserResendInvitationActionItem } from './UserResendInvitationActionItem';
import { UsersListResultsTableData } from './UsersListResultsTableData';
import { useUserActionModals } from './useUserActionModals';

interface UsersListResultsProps {
  readonly invitedUsersOnly?: boolean;
  readonly showSearch: boolean;
}

export const UsersListResults = ({ invitedUsersOnly, showSearch }: UsersListResultsProps) => {
  const { isMobile } = usePlatformBreakpoint();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { renderModals, showRemoveUserFromOrgModal, showAddToSchedulingInstanceModal, showChangeRoleModal } =
  useUserActionModals();

  const { currentUserId, enterpriseId, hasSsoProvider, hasLoaded, users } = useAppSelector((state: RootState) => {
    const hasLoadedUsers =
    state.orgUsers && !state.orgUsers.isLoading && state.invitedOrgUsers && !state.invitedOrgUsers.isLoading;
    const enterprise = selectEnterprise(state.currentUser);
    return {
      currentUserId: state.currentUser?.currentUser.id,
      enterpriseId: enterprise?.id,
      hasSsoProvider: enterpriseHasSsoProvider(enterprise),
      hasLoaded: hasLoadedUsers,
      users: invitedUsersOnly ? selectAllOrgUsers(state.invitedOrgUsers) : selectAllInvitedOrgUsers(state.orgUsers)
    };
  });

  // TODO: This does not factor in fetching more than 1000 users
  //  https://squarespace.atlassian.net/browse/AE-754
  useEffect(() => {
    if (!enterpriseId) {
      throw new Error('User is not connected to an Enterprise');
    }

    dispatch(fetchInvitedOrgUsers(enterpriseId));
    dispatch(fetchOrgUsers(enterpriseId));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!currentUserId || !enterpriseId) {
    return null;
  }

  let statusLabel = t("Status", null, { project: 'enterprise-dashboard' });
  let dateAddedTooltip = t("Date when a user is active. If the invitation is still pending, this will be the date that the invitation was sent.",

  {}, {
    project: 'enterprise-dashboard' });

  let dateAddedLabel = t("Date Added", {}, { project: 'enterprise-dashboard' });

  if (hasSsoProvider) {
    dateAddedTooltip = t("The date a user first signed in.", {}, { project: 'enterprise-dashboard' });
  }

  if (invitedUsersOnly) {
    statusLabel = t("Invitation Status", null, { project: 'enterprise-dashboard' });
    dateAddedTooltip = t("Date when a user was invited to your organization.", {}, { project: 'enterprise-dashboard' });
    dateAddedLabel = t("Date invited", {}, { project: 'enterprise-dashboard' });
  } else {
    dateAddedLabel = t("Date active", {}, { project: 'enterprise-dashboard' });
    dateAddedTooltip = t("The date a user accepted their invitation.", {}, { project: 'enterprise-dashboard' });
  }

  const activeColumns: ColumnDef<OrgUser, any>[] = [
  {
    accessorKey: 'name',
    sortingFn: 'alphanumeric',
    header: ({ column }: any) =>
    <TableHeader label={t("Name", null, { project: 'enterprise-dashboard' })} column={column} />,

    cell: (cell) => {
      const original: SqspUser = cell.row.original as SqspUser;
      // don't link to current user's page
      if (original.id === currentUserId) {
        return (
          <Text.Body m={0} fontWeight={isMobile ? 500 : 'inherit'}>
              {cell.getValue()}
            </Text.Body>);

      }
      return (
        <Text.Body m={0} sx={{ fontWeight: isMobile ? 500 : 'inherit' }}>
            <TextLink
            css={{
              textDecoration: 'none',
              '&:hover': {
                textDecoration: 'underline'
              }
            }}
            onClick={() => navigate(NAV_USER_VIEW(cell.row.original.id))}>

              {cell.getValue()}
            </TextLink>
          </Text.Body>);

    }
  },
  {
    accessorKey: 'email',
    sortingFn: 'alphanumeric',
    header: ({ column }: any) =>
    <TableHeader label={t("Email Address", null, { project: 'enterprise-dashboard' })} column={column} />,

    cell: (cell) =>
    <Text.Body m={0} sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {cell.getValue()}
        </Text.Body>

  },
  {
    accessorKey: 'role',
    header: ({ column }: any) =>
    <TableHeader
      label={t("Role", null, { project: 'enterprise-dashboard' })}
      column={column}
      tooltip={t("This is the user's role within the organization.", {}, { project: 'enterprise-dashboard' })} />,


    cell: (cell) => {
      return (
        <Text.Body m={0} fontSize={3}>
            {sqspUserRoleMap(cell.row.original.role)}
          </Text.Body>);

    }
  },
  {
    accessorKey: 'status',
    header: ({ column }: any) => <TableHeader label={statusLabel} column={column} />,
    cell: (cell) => {
      return <UserStatusChip status={cell.getValue()} />;
    }
  },
  {
    accessorKey: 'dateAdded',
    header: ({ column }: any) => <TableHeader label={dateAddedLabel} column={column} tooltip={dateAddedTooltip} />,
    cell: (cell) => <Text.Body m={0}>{tsToDateString(cell.getValue())}</Text.Body>
  },
  {
    accessorKey: 'id',
    id: ACTIONS_IDENTIFIER, // used for filtering out this cell during Mobile render
    header: t("Actions", null, { project: 'enterprise-dashboard' }),
    cell: (cell) => {
      const ariaLabel = t("Open User Actions Menu", null, { project: 'enterprise-dashboard' });
      const original: SqspUser = cell.row.original as SqspUser;
      const menuItems = ({ onRequestClose }: {onRequestClose: () => void;}) =>
      <Stack
        py={isMobile ? 0 : 1}
        direction="column"
        divider={isMobile && <Divider as="div" role="presentation" />}>

            {original.status === 'ACCEPTED' &&
        <ActionList.Item
          as="div"
          onClick={() => {
            navigate(NAV_USER_VIEW(cell.row.original.id));
          }}>

                <T project="enterprise-dashboard">{"Go to User Profile"}</T>
              </ActionList.Item>
        }
            {isPendingInvitationStatus(cell.row.original.status) ?
        <UserResendInvitationActionItem
          inviteCode={original.inviteCode}
          enterpriseId={enterpriseId}
          onRequestClose={onRequestClose} /> :


        <>
                <ActionList.Item
            as="div"
            onClick={() => {
              showChangeRoleModal(cell.getValue());
            }}>

                  <T project="enterprise-dashboard">{"Change role"}</T>
                </ActionList.Item>
                {cell.row.original.role === ORG_MEMBER &&
          <ActionList.Item as="div" onClick={() => showAddToSchedulingInstanceModal(cell.getValue())}>
                    <T project="enterprise-dashboard">{"Add user to a Scheduling Instance"}</T>
                  </ActionList.Item>
          }
              </>
        }
            {!hasSsoProvider &&
        <ActionList.Item as="div" onClick={() => showRemoveUserFromOrgModal(original)} color="red.400">
                {isPendingInvitationStatus(cell.row.original.status) ? <T
            project="enterprise-dashboard">{"Cancel invitation"}</T> : <T

            project="enterprise-dashboard">{"Remove user from org"}</T>
          }
              </ActionList.Item>
        }
          </Stack>;


      // don't show actions for current user
      if (original.id === currentUserId) {
        return <></>;
      }

      if (isMobile) {
        return (
          <ActionList.Sheet
            aria-label={ariaLabel}
            renderHeader={() =>
            <Text.Body textAlign="center" fontWeight={500} pt={3}>
                  {cell.row.original.name}
                </Text.Body>
            }>

              {menuItems}
            </ActionList.Sheet>);

      }

      return (
        <ActionList.PopOver position="bottom" anchorPoint={{ x: 'center', y: 'top' }} aria-label={ariaLabel}>
            {menuItems}
          </ActionList.PopOver>);

    },
    enableSorting: false
  }];


  if (!hasLoaded && !users.length) {
    return (
      <Flex height="300px" justifyContent="center" alignItems="center" flexDirection="column">
        <AcuityLoader />
      </Flex>);

  }

  const shouldShowSearch = (!isMobile || showSearch) && users.length > 0;

  return (
    <Box position="relative">
      <Table
        columns={activeColumns}
        data={users}
        enableSort
        enablePagination={users.length > 0}
        enableSearch={users.length > 0}
        initialState={{
          columnVisibility: {
            status: !invitedUsersOnly
          },
          sorting: [
          {
            id: 'name',
            desc: false
          }],

          pagination: {
            pageIndex: 0,
            pageSize: PAGINATION_LIMIT_MIN
          }
        }}>

        <Table.Controls>
          <Table.Controls.Left>
            {isMobile &&
            <Table.Sort
              sortingOptions={[
              {
                id: 'name',
                ascLabel: ' Name (A-Z)',
                descLabel: ' Name (Z-A)'
              },
              {
                id: 'status',
                ascLabel: 'User Status (A-Z)',
                descLabel: 'User Status (Z-A)'
              }]
              } />

            }
            {shouldShowSearch &&
            <Table.Controls.Slot sx={{ marginBottom: 5 }}>
                <Table.Search isOpen={shouldShowSearch} />
                {!isMobile && <Table.RecordCount />}
              </Table.Controls.Slot>
            }
          </Table.Controls.Left>
        </Table.Controls>
        <UsersListResultsTableData
          enterpriseId={enterpriseId}
          hasLoaded={hasLoaded}
          users={users}
          invitedUsersOnly={Boolean(invitedUsersOnly)} />

        {users.length > 0 ? <TablePagination /> : null}
      </Table>
      {renderModals()}
    </Box>);

};