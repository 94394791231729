import React from 'react';
import { createHashRouter, RouteObject } from 'react-router-dom';
import App from './App';
import { ProtectedRoute } from './components/ProtectedRoute';
import { Reporting } from './components/Reporting';
import { Settings } from './components/Settings/Settings';
import { OrganizationInfo } from './components/Settings/OrganizationInfo';
import { ApiKey } from './components/Settings/ApiKey';
import { SchedulingInstancesContainer } from './components/SchedulingInstancesContainer';
import { FEATURE_FLAGS } from './const/feature-flags';
import {
  ROUTE_API_KEY,
  ROUTE_DASHBOARD,
  ROUTE_HOME,
  ROUTE_INVITED,
  ROUTE_ORGANIZATION,
  ROUTE_REPORTING,
  ROUTE_SETTINGS,
  ROUTE_SSO,
  ROUTE_USERS,
  ROUTE_VIEW,
} from './const/navigation';
import ErrorBoundary from './ErrorBoundary';
import { UserView, Users } from './components/Users';
import { ORG_MANAGER } from './models/SqspUser';
import { EnterpriseSso } from './components/Settings/EnterpriseSso/EnterpriseSso';

const routes: RouteObject[] = [
  {
    path: ROUTE_HOME,
    element: <App />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        element: <SchedulingInstancesContainer />,
        index: true,
      },
      {
        path: ROUTE_DASHBOARD,
        element: <SchedulingInstancesContainer />,
      },
      {
        path: ROUTE_USERS,
        children: [
          {
            index: true,
            element: (
              <ProtectedRoute allowedRoles={[ORG_MANAGER]} sqspOnly>
                <Users />
              </ProtectedRoute>
            ),
          },
          {
            path: ROUTE_INVITED,
            element: (
              <ProtectedRoute allowedRoles={[ORG_MANAGER]} sqspOnly>
                <Users showInvitedUsers />
              </ProtectedRoute>
            ),
          },
          {
            path: ROUTE_VIEW,
            element: (
              <ProtectedRoute allowedRoles={[ORG_MANAGER]} sqspOnly>
                <UserView />
              </ProtectedRoute>
            ),
          },
        ],
      },
      {
        path: ROUTE_SETTINGS,
        element: (
          <ProtectedRoute allowedRoles={[ORG_MANAGER]}>
            <Settings />
          </ProtectedRoute>
        ),
        children: [
          {
            index: true,
            element: (
              <ProtectedRoute allowedRoles={[ORG_MANAGER]}>
                <OrganizationInfo />
              </ProtectedRoute>
            ),
          },
          {
            path: ROUTE_ORGANIZATION,
            element: (
              <ProtectedRoute allowedRoles={[ORG_MANAGER]}>
                <OrganizationInfo />
              </ProtectedRoute>
            ),
          },
          {
            path: ROUTE_API_KEY,
            element: (
              <ProtectedRoute allowedRoles={[ORG_MANAGER]}>
                <ApiKey />
              </ProtectedRoute>
            ),
          },
          {
            path: ROUTE_SSO,
            element: (
              <ProtectedRoute
                allowedRoles={[ORG_MANAGER]}
                sqspOnly
                featureFlag={FEATURE_FLAGS.ENTERPRISE_SHOW_SSO_IN_DASHBOARD}
              >
                <EnterpriseSso />
              </ProtectedRoute>
            ),
          },
        ],
      },
      {
        path: ROUTE_REPORTING,
        element: (
          <ProtectedRoute allowedRoles={[ORG_MANAGER]} sqspOnly>
            <Reporting />
          </ProtectedRoute>
        ),
      },
    ],
  },
];

// Using a HashRouter is not recommended by React Router, but because production / QA / local dev rely on different
// URL structures, this *should* be the safest way to handle routing for this app because we do not have the ability
// to control the URI pathing between our environments easily (as of June 2024)
// https://reactrouter.com/en/main/routers/create-hash-router
export const router = createHashRouter(routes);
